.steps-pagination-text {
  text-align: left;
  font-size: 11px;
  color: #b6b5b3;
  margin-bottom: 15px;
}

.form-box {
  position: relative;
  height: 100%;
}

.form {
  height: 100%;
  padding-top: 100px;
  padding-bottom: 50px;
}

.background-image-world-cup {
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url("world_cup_fundo_splash_desktop.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.background-image-gerdau {
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url("gerdau_fundo_splash_desktop.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.background-image-vivenda {
  height: 100%;
  background-image: url("fundo_splash_desktop.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  html {
    background-position-y: -6vh;
    background-position-x: -25vw;
    background-size: 140%;
  }
}

@media only screen and (max-width: 893px) {
  html {
    background-position-y: -6vh;
    background-position-x: -45vw;
    background-size: 170%;
  }
}

@media only screen and (max-width: 425px) {
  .background-image-world-cup {
    background-position-y: -10vh;
    background-position-x: 0vw;
    background-size: 100%;
    background-image: url("world_cup_fundo_splash_mobile.png");
  }
  .background-image-vivenda {
    background-position-y: -10vh;
    background-position-x: 0vw;
    background-size: 100%;
    background-image: url("fundo_splash_mobile.png");
  }
  .background-image-gerdau {
    background-position-y: -10vh;
    background-position-x: 0vw;
    background-size: 100%;
    background-image: url("gerdau_fundo_splash_mobile.png");
  }
  .steps-pagination-text {
    text-align: left;
    font-size: 4vmin;
    color: #b6b5b3;
    margin-bottom: 15px;
  }
}
